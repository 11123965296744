import Footer from '@components/Footer';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React from 'react';
import Spacer from './Spacer';
import Header from './Header';

interface ILayout {
  props: {
    path: string;
  };
}

const Layout: React.FC<ILayout> = ({ children, props }) => {
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box position="relative" minHeight="100vh">
      <Header path={props.path} />
      {<Spacer spacing={isMobile ? 2 : 8} />}
      {children}
      <Spacer spacing={80} />
      <Footer />
    </Box>
  );
};

export default Layout;
