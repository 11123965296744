module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"easy-education","short_name":"easy-education","start_url":"/","background_color":"#30bfc5","theme_color":"#30bfc5","display":"minimal-ui","icon":"src/images/favicon-ee.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"1bba4e22d389e7d7edb227d4d5603f37"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
