import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#30bfc5'
    },
    secondary: {
      main: '#494c53'
    },
    text: {
      primary: '#3D474D'
    }
  },
  typography: {
    fontFamily: `Titillium, sans-serif`
  },
  shadowPrimary: {
    boxShadow:
      '-30px -30px 80px #FFFFFF, 30px 30px 80px rgba(55, 84, 170, 0.1)',
    background:
      'radial-gradient(53.23% 53.23% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 100%), #F1F3F6'
  }
});
