import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import Layout from './src/components/shared/Layout';
import theme from './src/styles/theme';
import './src/styles/global.css';

export const wrapPageElement = ({ element, ...props }) => (
  <Layout {...props}>{element}</Layout>
);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
);
