import React from 'react';
import { withStyles, Typography, Box, Theme, Link } from '@material-ui/core';
import { WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  typography: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  },
  link: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
});

interface TextColumnProps extends WithStyles<typeof styles> {
  item: string[];
}

const TextColumn: React.FC<TextColumnProps> = ({ classes, item }) => (
  <Box>
    {item.map((i) => {
      if (i.includes('@') || i.includes('+46')) {
        return (
          <Link
            key={i}
            className={classes.link}
            color="textPrimary"
            target="_blank"
            href={`${i.includes('@') ? 'mailto' : 'tel'}:${i}`}
          >
            {i}
          </Link>
        );
      }
      return (
        <Typography color="textPrimary" className={classes.typography} key={i}>
          {i}
        </Typography>
      );
    })}
  </Box>
);

export default withStyles(styles)(TextColumn);
