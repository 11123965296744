import React from 'react';
import { Theme, withTheme } from '@material-ui/core';

interface SpacerProps {
  orientation?: 'vertical' | 'horizontal';
  spacing?: number;
  theme: Theme;
}

const Spacer: React.FC<SpacerProps> = ({
  theme,
  orientation = 'vertical',
  spacing = 1
}) => (
  <div
    style={{
      [orientation === 'vertical' ? 'height' : 'width']: theme.spacing(spacing)
    }}
  />
);

export default withTheme(Spacer);
