import React from 'react';
import { withStyles, Grid, IconButton, Theme } from '@material-ui/core';
import { Facebook, Instagram, LinkedIn } from '@material-ui/icons';
import { facebookURL, instagramURL, linkedInURL } from '@utils/constants';
import { WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  containerIcon: {
    backgroundColor: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.text.primary
    }
  }
});

const SocialContainer: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
  const Icon = ({ icon: IconComponent, ...props }) => (
    <IconButton className={classes.containerIcon} target="__blank" {...props}>
      <IconComponent color="primary" />
    </IconButton>
  );
  return (
    <Grid container alignItems="center" justify="center" spacing={2}>
      <Grid item>
        <Icon icon={LinkedIn} href={linkedInURL} aria-label="LinkedIn" />
      </Grid>
      <Grid item>
        <Icon icon={Instagram} href={instagramURL} aria-label="Instagram" />
      </Grid>
      <Grid item>
        <Icon icon={Facebook} href={facebookURL} aria-label="Facebook" />
      </Grid>
    </Grid>
  );
};
export default withStyles(styles)(SocialContainer);
