import React from 'react';
import { withStyles, Box, Grid, Typography, Theme } from '@material-ui/core';
import SocialContainer from '@components/SocialContainer';
import TextColumn from '@components/TextColumn';
import Spacer from '@components/shared/Spacer';
import { footerInfoArray } from '@utils/constants';
import { WithStyles } from '@material-ui/styles';

const styles = (theme: Theme) => ({
  wrapper: {
    padding: theme.spacing(0, 2, 2),
    position: 'absolute' as 'absolute',
    bottom: 0,
    left: 0,
    right: 0
  },
  container: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 16,
    padding: theme.spacing(3)
  },
  containerIcon: {
    margin: theme.spacing(0, 1),
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF'
    }
  },
  typoCopy: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    }
  }
});

const Footer: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
  return (
    <Box className={classes.wrapper}>
      <Box className={classes.container}>
        <SocialContainer />
        <Spacer spacing={10} />
        <Grid container spacing={4}>
          {footerInfoArray.map((item) => (
            <Grid
              item
              container
              justify="center"
              key={item[0]}
              xs={12}
              md={12 / footerInfoArray.length}
            >
              <TextColumn item={item} />
            </Grid>
          ))}
        </Grid>
        <Spacer spacing={11} />
        <Typography
          variant="body2"
          color="textPrimary"
          align="right"
          className={classes.typoCopy}
        >
          ©Copyright 2020 - Easy Education Sweden AB - All rights reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Footer);
