import { Button, Grid, Theme, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';
import { Link } from 'gatsby';

const styles = (theme: Theme) => ({
  containerLogin: {
    padding: theme.spacing(4)
  },
  buttonLogin: {
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  link: {
    fontWeight: 600,
    fontSize: 20,
    color: theme.palette.primary.main
  }
});

interface IHeader extends WithStyles<typeof styles> {
  path: string;
}

const Header: React.FC<IHeader> = ({ classes, path }) => (
  <Grid
    container
    alignItems="center"
    justify="space-between"
    className={classes.containerLogin}
  >
    <Grid item>
      {path === '/' ? (
        <Typography color="textPrimary" variant="h6">
          Företag?{' '}
          <Link className={classes.link} to="/foretag">
            Se hit!
          </Link>
        </Typography>
      ) : (
        <Link className={classes.link} to="/">
          Hem
        </Link>
      )}
    </Grid>
    <Grid item>
      <Button
        color="primary"
        variant="contained"
        className={classes.buttonLogin}
        size="large"
        component="a"
        href="https://app.easyeducation.se"
      >
        logga in
      </Button>
    </Grid>
  </Grid>
);

export default withStyles(styles)(Header);
