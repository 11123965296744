import React from 'react';

import review1 from '@images/review-avatar/review1-min.webp';
import review2 from '@images/review-avatar/review2-min.webp';
import review3 from '@images/review-avatar/review3-min.webp';

export const facebookURL = 'https://www.facebook.com/easyeducationswe/';
export const instagramURL = 'https://www.instagram.com/easyeducationsweden/';
export const linkedInURL =
  'https://www.linkedin.com/company/easy-education-sweden-ab/';

export const footerInfoArray = [
  ['Gustav Ahlm', '+46 76-187 35 44', 'gustav.ahlm@easyeducation.se'],
  ['Alexander Sager', '+46 70-872 44 06', 'alexander.sager@easyeducation.se'],
  ['Andra Långgatan 46', '413 27 Göteborg', 'info@easyeducation.se']
];

export const reviews = [
  {
    name: 'Minton Lejon',
    profession: 'Student',
    text:
      'Fick möjlighet att prova tjänsten tidigt och kan starkt rekommendera den till andra studenter!',
    image: review1
  },
  {
    name: 'Emelie Jansson',
    profession: 'Student',
    text:
      'Easy Education har verkligen underlättat för mig under mina studier. Det jag gillar mest med tjänsten är att allt material jag behöver är så lättillgängligt, det är toppen!',
    image: review2
  },
  {
    name: 'Albin Carlsson',
    profession: 'Lärare',
    text:
      'Som lärare och föreläsare ser jag stora fördelar i att kunna distribuera litteratur och material digitalt via Easy Education till mina studenter. Det skapar ett högre engagemang i kombination med sparad tid.',
    image: review3
  }
];

export const signUpFields = [
  {
    name: 'firstName',
    label: 'Förnamn',
    type: 'text'
  },
  {
    name: 'lastName',
    label: 'Efternamn',
    type: 'text'
  },
  {
    name: 'email',
    label: 'E-post',
    type: 'email'
  },
  {
    name: 'emailConfirm',
    label: 'Bekräfta e-post',
    type: 'email'
  },
  {
    name: 'school',
    label: 'Skola',
    type: 'text'
  },
  {
    name: 'program',
    label: 'Program',
    type: 'text'
  },
  // TODO: Add these fields when ee platform has been updated w/ new features
  // {
  //   name: 'password',
  //   label: 'Lösenord',
  //   type: 'password'
  // },
  // {
  //   name: 'passwordConfirm',
  //   label: 'Bekräfta lösenord',
  //   type: 'password'
  // },
  {
    name: 'allowNews',
    label: 'Jag godkänner att nyheter om Easy Education skickas till min e-post'
  }
];

export const textImageData = [
  {
    title: 'Vad är Easy Education?',
    body: (
      <span>
        {' '}
        Easy Education är en plattform som samlar allt digitalt
        utbildningsmaterial för studenter vid universitet, högskolor och
        yrkeshögskolor online. Du som student får direkt tillgång till relevant
        studiematerial samlat på en enda plats, med ett enkelt och enhetligt
        gränssnitt. Plattformen ger dig även en mängd smarta studieverktyg för
        att studera mer effektivt. Allt detta erbjuder vi helt gratis för dig
        som student.
        <br />
        <br />
        Tid är pengar, så varför inte spara både och med Easy Education?
      </span>
    ),
    shapeSmallStyle: { bottom: '-30%', right: 0 },
    shapeLargeStyle: { bottom: '-30%' }
  },
  {
    title: 'Betala mindre, lär dig mer.',
    body: (
      <span>
        Vi arbetar ständigt med att uppdatera plattformen med ännu mer relevant
        innehåll, och vår intention är att i framtiden kunna erbjuda Sveriges
        billigaste kursmaterial. Vi tar bort onödiga kostnader samt det pålägg
        andra återförsäljare idag adderar till priset för dina studieböcker.
        Genom att även erbjuda uthyrning ges du som student möjligheten att
        anpassa priset efter just ditt behov. Anmäl ditt intresse för att ge oss
        möjlighet att erbjuda just din kurslitteratur.
      </span>
    ),
    shapeSmallStyle: { top: '-35%', left: 0 },
    shapeLargeStyle: { top: '-20%', left: '-5%' }
  },
  {
    title: 'Full uppsättning av studieverktyg.',
    body: (
      <span>
        På samma plattform där du köper och samlar allt ditt material, får du
        också tillgång till en mängd smarta digitala studieverktyg helt gratis.
        Verktygen är utformade för att ge dig en mer effektiv och
        individanpassad inlärning, där du kan lägga upp dina studier på det sätt
        som passar dig bäst.
        <br />
        <br />
        Verktyg:
        <br />
        • Markera och kommentera i boktexten
        <br />
        • Ha ett digitalt anteckningsblock öppet samtidigt som du läser din bok
        <br />
        • Sök upp innehåll i boken på Wikipedia, Synonymer.se eller Google, med
        ett enkelt knapptryck
        <br />
        • Snabb-navigera via sidnummer, kapitel eller ord
        <br />• Summera alla dina färgmarkeringar och kommentarer med ett enda
        knapptryck
      </span>
    ),
    shapeSmallStyle: { bottom: '-10%', right: 0 },
    shapeLargeStyle: { bottom: '-10%' }
  },
  {
    title: 'Digital åtkomst var som helst, när som helst.',
    body: (
      <span>
        Är du trött på att allt studiematerial ser olika ut, är i olika format,
        och är spritt över en mängd olika platser (både digitala och fysiska)?
        Det är vi också. Därför hjälper vi alla skolor att samla allt sitt
        material på vår plattform, som du sen kan jobba med på ett effektivt
        sätt som passar dig.
        <br />
        <br />
        Låter Easy Education som något för dig? Tjänsten släpps snart, ställ dig
        i kö för att vara en av de första som får ett konto!
      </span>
    ),
    shapeSmallStyle: { bottom: '-30%', left: 0 },
    shapeLargeStyle: { bottom: '-30%', left: '-5%' }
  }
];

export const LOGIN_URL = 'https://www.easyeducation.se/login';

export const serviceTitles = ['Spara pengar', 'Studieverktyg', 'Spara tid'];
